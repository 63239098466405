import {EventBus} from "@/service/EventBus";
import {BarcodeAPI} from "@/api/BarcodeAPI";
import {CodeType} from "@/enum/code_type";
import {ProductAPI} from "@/api/ProductAPI";
import {Store} from "@/service/store/Store";
import {Channel} from "@/service/Channel";
import {StockStatusAPI} from "@/api/StockStatusAPI";
import {TaskStockPickingAPI} from "@/api/TaskStockPickingAPI";
import {TaskShippingType} from "@/enum/task_shipping_type";
import {APIFilterOP, APIFilters} from "@/service/APIFilters";
import {mapReduce} from "@/utils/array";

export default function registerGlobalListeners() {

    const getVueFromElement = el => el.__vue__ || getVueFromElement(el.parentNode);

    window.onBarcodeRead = data => {
        // Send to active listeners
        EventBus.$emit('onBarcodeRead', data);

        // Set to focused input
        const el = document.activeElement;
        if (el.nodeName === 'INPUT') {
            const vueEl = getVueFromElement(el);
            if (vueEl.$options._componentTag === 'v-autocomplete') {
                vueEl.$emit('update:search-input', data);
            } else {
                vueEl.$emit('input', data);
            }
        }
    };

    /**
     * Allows to import products in batch
     * @param source {string} CSV in format: manufacturer_id;name;model;?description;?suggested_buy_price;?barcode;?[barcodeQuantity;barcodeCode;]*\n...
     * @see https://www.notion.so/jagu-cz/Triky-3c15854867e04964bb546668e37f3d3c
     */
    window.onProductImport = source => {
        source.split('\n')
            .map(item => item.split(';'))
            .forEach(([manufacturer_id, name, model, description, suggested_buy_price, onePieceBarcode, ...multiPieceBarcodes]) => {
                const data = {
                    name: name.substring(0, 50),
                    model: model.substring(0, 30),
                    manufacturer_id: Number(manufacturer_id),
                    description: description,
                    suggested_buy_price: Number(suggested_buy_price)
                };
                const barcodeTuples = [];
                if (onePieceBarcode) {
                    barcodeTuples.push({
                        quantity: 1,
                        code: onePieceBarcode
                    });
                }
                for (let i = 0; i < multiPieceBarcodes.length; ++i) {
                    if (multiPieceBarcodes[i + 1]) {
                        barcodeTuples.push({
                            quantity: Number.parseInt(multiPieceBarcodes[i], 10),
                            code: multiPieceBarcodes[i + 1]
                        });
                        i++;
                    }
                }
                if (name.length > 50 || model.length > 30) {
                    if (data.description) {
                        data.description += '\n';
                    } else {
                        data.description = '';
                    }
                    data.description += name + ' - ' + model;
                }
                ProductAPI.create(data)
                    .then(createResponse => {
                        const productId = createResponse.headers.location.split('/').pop();
                        return ProductAPI.getAllInstances(productId)
                            .then(async instanceResponse => {
                                const instanceId = instanceResponse.data.items[0].id;
                                for (const barcodePayload of barcodeTuples) {
                                    await ProductAPI.createInstanceBarcode(productId, instanceId, barcodePayload)
                                        .catch(() => {
                                            window.console.error(`${manufacturer_id};${name};${model};${onePieceBarcode};BARCODE CONFLICT FOR ${barcodePayload.code}`);
                                        });
                                }
                            });
                    });
            });
    };

    /**
     * Allows to import images in batch
     * @param source {string} CSV in format: EAN;url\nEAN;url...
     * @see https://www.notion.so/jagu-cz/Triky-3c15854867e04964bb546668e37f3d3c
     */
    window.onImageImport = source => {
        source.split('\n')
            .map(item => item.split(';'))
            .forEach(([ean, url]) => {
                BarcodeAPI.decode(ean)
                    .then(response => {
                        const barcodeInfo = response.data;
                        if (barcodeInfo.type === CodeType.PRODUCT_INSTANCE) {
                            ProductAPI.createExternalImage(barcodeInfo.object_info.product.id, url, true);
                        }
                    });
            });
    };

    /**
     * Locally stores all product images which are using an external link.
     * @see https://www.notion.so/jagu-cz/Triky-3c15854867e04964bb546668e37f3d3c
     */
    window.onStoreAllImagesLocally = () => {
        ProductAPI.getAllPages().then(response => {
            response.data.items.forEach(product => {
                ProductAPI.getAllImages(product.id).then(response => {
                    response.data.forEach(image => {
                        if (image.type === 'external') {
                            ProductAPI.storeExternalImage(product.id, image.id);
                        }
                    });
                });
            });
        });
    };

    /**
     * Creates a single huge-ass stock-picking for everything in a given subordinate stock, as 'personal-collection' type
     * @see https://www.notion.so/jagu-cz/Triky-3c15854867e04964bb546668e37f3d3c
     */
    window.onCreateCompleteSubstockPicking = (subStockId) => {
        StockStatusAPI.getShortAllPages({
            filter: APIFilters.makeFilter([
                {[APIFilterOP.EQUALS]: {'substock.id': subStockId}},
                {[APIFilterOP.GREATER_THAN]: {quantity: 0}}
            ])
        }).then(response => {
            TaskStockPickingAPI.create({
                subordinate_stock_id: subStockId,
                shipping_type: TaskShippingType.PERSONAL_COLLECTION,
                items: mapReduce(response.data.items, ['product_instance_id'], 'quantity')
                    .map(item => ({
                        product_instance_id: item.product_instance_id,
                        quantity: item.quantity,
                    })),
            });
        });
    };

    /**
     * Processes a self-assigned, 'personal-collection' type of stock-picking
     * @see https://www.notion.so/jagu-cz/Triky-3c15854867e04964bb546668e37f3d3c
     */
    window.onDoStockPicking = (stockPickingId) => {
        TaskStockPickingAPI.get(stockPickingId).then(response => {
            const stockPicking = response.data;
            TaskStockPickingAPI.getAllItems(stockPickingId)
                .then(response => {
                    for (const item of response.data) {
                        let quantity_todo = item.quantity_to_move - item.processed_quantity;
                        StockStatusAPI.getShortAllPages({
                            filter: APIFilters.makeFilter([
                                {[APIFilterOP.EQUALS]: {'substock.id': stockPicking.substock_id}},
                                {[APIFilterOP.EQUALS]: {'product_instance.id': item.product_instance_id}},
                                {[APIFilterOP.GREATER_THAN]: {quantity: 0}},
                            ])
                        }).then(response => {
                            for (const item_location of response.data.items) {
                                const quantity = Math.min(item_location.quantity, quantity_todo);
                                TaskStockPickingAPI.pickUpFromSource(stockPickingId, item.id, item_location.stock_location.id, quantity);
                                quantity_todo -= quantity;
                                if (quantity_todo <= 0) {
                                    break;
                                }
                            }
                        });
                    }
                });
        });
    };

    let reading = false;
    let readerCode = '';
    let timeout = null;
    document.addEventListener('keyup', function (e) {
        if (e.code === 'AltLeft') {
            if (!reading) {
                // This is probably barcode reader input, start reading
                reading = true;
                // Set timeout on first read to reset code after some time
                timeout = setTimeout(() => {
                    reading = false;
                    readerCode = '';
                }, 400);
            }
            readerCode += e.key;
        } else if (reading && e.code === 'Enter') {
            window.onBarcodeRead(readerCode);
            reading = false;
            readerCode = '';
            clearTimeout(timeout);
        }
    });

    window.matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', ({matches}) => {
            if (Store.getters['userConfig/theme'] === 'system') {
                EventBus.$emit('setDarkMode', matches);
            }
        });

    // auth sharing listeners

    // listen to session changes from other tabs to keep login in sync
    window.addEventListener('storage', (event) => {
        if (event.key === 'session') {
            Store.dispatch('oauth/tryToRestoreSession');
        }
    });

    const TAB_COUNTER_KEY = 'swordfish_tab_count';
    // increment tab counter because this tab was just opened
    const openTabCount = +localStorage.getItem(TAB_COUNTER_KEY) || 0;
    localStorage.setItem(TAB_COUNTER_KEY, (openTabCount + 1).toString());

    // subtract this tab from tab counter when it is closed
    window.addEventListener("beforeunload", () => {
        const openTabCount = +localStorage.getItem(TAB_COUNTER_KEY) || 1;
        localStorage.setItem(TAB_COUNTER_KEY, (openTabCount - 1).toString());
    });

    Channel.registerEventHandler("logout", () => Store.dispatch('oauth/confirmLogout'));
}
