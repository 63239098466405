<template>
  <div>
    <v-subheader>
      {{ label }}
      <v-spacer />
      <v-tooltip left>
        <template #activator="{ on }">
          <v-icon
            class="text--disabled mr-2"
            v-on="on"
          >
            $info
          </v-icon>
        </template>
        {{ $t('homepage.stats.orderCountDisclaimer') }}
      </v-tooltip>
      <DashboardConfigLink
        :config-enter-callback="configEnterCallback"
      />
      <v-tooltip left>
        <template #activator="{ on }">
          <v-icon
            class="text--disabled dashboardIcon"
            :class="{loadingIcon: loading}"
            :disabled="loading"
            v-on="on"
            @click="reloadData"
          >
            $reloadData
          </v-icon>
        </template>
        {{ $t('base.reload') }}
      </v-tooltip>
    </v-subheader>
    <v-card>
      <v-timeline dense>
        <v-timeline-item
          v-for="(item, index) of timelineItems"
          :key="index"
          :color="'primary ' + iconColor(index)"
          :icon="item.icon"
          fill-dot
        >
          <v-skeleton-loader
            v-if="loading"
            type="button"
            class="py-0 my-0"
          />
          <template v-else>
            <span class="text-h5">
              {{ item.value }}
            </span>
            <span class="caption">
              {{ $t(item.label) }}
            </span>
          </template>
        </v-timeline-item>
      </v-timeline>
    </v-card>
  </div>
</template>

<script>
    import {TaskExternalOrderAPI} from "@/api/TaskExternalOrderAPI";
    import {ExternalOrderState} from "@/enum/task_state";
    import {APIFilterOP, APIFilters} from "@/service/APIFilters";
    import DashboardConfigLink from "@/app/homepage/components/DashboardConfigLink.component";
    import {AutoRefreshMixin} from "@/app/mixins/AutoRefreshMixin";

    export default {
        name: "OrdersStatsTimeline",
        components: {DashboardConfigLink},
        mixins: [AutoRefreshMixin],
        props: {
            label: {
                type: String,
                default: 'tasks'
            },
            subStockIds: {
                type: Array,
                default: () => []
            },
            configEnterCallback: {
                type: Function,
                default: () => Promise.resolve()
            }
        },
        data: () => ({
            loading: false,
            created: null,
            toBePicked: null,
            beingPicked: null,
            toBePacked: null,
            beingPacked: null,
            packed: null,
            shipped: null,
            returning: null,
            delivered: null
        }),
        computed: {
            timelineItems: function () {
                return [{
                    icon: '$taskExternalOrder',
                    label: 'homepage.stats.orders.unprocessed',
                    value: this.created
                }, {
                    icon: '$storeKeeper',
                    label: 'homepage.stats.orders.toBePicked',
                    value: this.toBePicked
                }, {
                    icon: '$taskStockPicking',
                    label: 'homepage.stats.orders.beingPicked',
                    value: this.beingPicked
                }, {
                    icon: '$toBePacked',
                    label: 'homepage.stats.orders.toBePacked',
                    value: this.toBePacked
                }, {
                    icon: '$taskPreparePackage',
                    label: 'homepage.stats.orders.beingPacked',
                    value: this.beingPacked
                }, {
                    icon: '$waiting',
                    label: 'homepage.stats.orders.packed',
                    value: this.packed
                }, {
                    icon: '$handOver',
                    label: 'homepage.stats.orders.inShipping',
                    value: this.shipped
                }, {
                    icon: '$returning',
                    label: 'homepage.stats.orders.returning',
                    value: this.returning
                }, {
                    icon: '$success',
                    label: 'homepage.stats.orders.delivered',
                    value: this.delivered
                }];
            }
        },
        createdOrActivated: function () {
            this.reloadData();
        },
        methods: {
            iconColor: function (index) {
                const threshold = this.timelineItems.length - Math.ceil(this.timelineItems.length / 2);
                if (index < threshold) {
                    return 'lighten-' + (threshold - index);
                } else {
                    return 'darken-' + (index - threshold);
                }
            },
            reloadData: function () {
                this.loading = true;
                Promise.all([
                    this.getCountOfOrdersInEOState(ExternalOrderState.EO_STATE_CREATED).then(count => {
                        this.created = count;
                    }),
                    this.getCountOfOrdersInEOState(ExternalOrderState.EO_STATE_STOCK_PICKING_WAITING).then(count => {
                        this.toBePicked = count;
                    }),
                    this.getCountOfOrdersInEOState(ExternalOrderState.EO_STATE_STOCK_PICKING_IN_PROGRESS).then(count => {
                        this.beingPicked = count;
                    }),
                    this.getCountOfOrdersInEOState(ExternalOrderState.EO_STATE_PREPARE_PACKAGE_WAITING).then(count => {
                        this.toBePacked = count;
                    }),
                    this.getCountOfOrdersInEOState(ExternalOrderState.EO_STATE_PREPARE_PACKAGE_IN_PROGRESS).then(count => {
                        this.beingPacked = count;
                    }),
                    this.getCountOfOrdersInEOState(ExternalOrderState.EO_STATE_SHIPMENTS_WAITING).then(count => {
                        this.packed = count;
                    }),
                    this.getCountOfOrdersInEOState(ExternalOrderState.EO_STATE_SHIPPED).then(count => {
                        this.shipped = count;
                    }),
                    this.getCountOfOrdersInEOState(ExternalOrderState.EO_STATE_RETURNING).then(count => {
                        this.returning = count;
                    }),
                    this.getCountOfOrdersInEOState(ExternalOrderState.EO_STATE_DELIVERED).then(count => {
                        this.delivered = count;
                    })
                ]).catch(this.snack).finally(() => {
                    this.loading = false;
                });
            },
            getCountOfOrdersInEOState: function (eoState) {
                const filter = [
                    {
                        [APIFilterOP.IS_NULL]: 'history.active_to'
                    },
                    {
                        [APIFilterOP.EQUALS]: {
                            'history.state': eoState
                        }
                    }
                ];
                if (this.subStockIds.length > 0) {
                    filter.push({
                        [APIFilterOP.IN]: {
                            'subordinate_stock.id': this.subStockIds
                        }
                    });
                }
                return TaskExternalOrderAPI.getAmount({
                    filter: APIFilters.makeFilter({
                        [APIFilterOP.AND]: filter
                    })
                }).then(response => {
                    return response.data.orders_amount;
                }).catch(this.snack);
            }
        }
    };
</script>

<style scoped lang="sass">
.loadingIcon
  animation: rotation 2s infinite linear
</style>
