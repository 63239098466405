<template>
  <div>
    <v-subheader>
      {{ label }}
      {{ tasks.length !== 0 ? (' (' + tasksCount + ')') : '' }}
      <v-spacer />
      <DashboardConfigLink
        :config-enter-callback="configEnterCallback"
      />
      <v-tooltip left>
        <template #activator="{ on }">
          <v-icon
            class="text--disabled dashboardIcon"
            :class="{loadingIcon: loading}"
            :disabled="loading"
            v-on="on"
            @click="reloadData"
          >
            $reloadData
          </v-icon>
        </template>
        {{ $t('base.reload') }}
      </v-tooltip>
    </v-subheader>
    <v-alert
      v-if="tasksCount === 0"
      :value="true"
      icon="$allTasksDone"
      color="primary"
      outlined
    >
      {{ $t('homepage.noTasks') }}
    </v-alert>
    <TaskListList
      v-else
      :tasks="tasks"
    />
    <v-btn
      v-show="moreDataAvailable"
      ref="moreData"
      :disabled="loading"
      block
      text
      color="secondary"
      @click="fetchMoreData"
    >
      {{ moreDataText }}
    </v-btn>
  </div>
</template>

<script>
    import {TaskAPI} from "@/api/TaskAPI";
    import TaskListList from "@/app/tasks/components/taskList/TaskListList.component";
    import {APIFilters} from "@/service/APIFilters";
    import {taskApi, taskListDetails} from "@/enum/task_type";
    import DashboardConfigLink from "@/app/homepage/components/DashboardConfigLink.component";
    import {AutoRefreshMixin} from "@/app/mixins/AutoRefreshMixin";

    export default {
        name: "TaskList",
        components: {DashboardConfigLink, TaskListList},
        mixins: [AutoRefreshMixin],
        props: {
            label: {
                type: String,
                default: 'tasks'
            },
            filter: {
                type: [Object, Array],
                default: undefined
            },
            sort: {
                type: Object,
                default: null
            },
            configEnterCallback: {
                type: Function,
                default: () => Promise.resolve()
            }
        },
        data: () => ({
            loading: false,
            loadingPromise: null,
            tasks: [],
            tasksCount: 0,
            page: 1,
            itemsPerPage: 10,
            active: true
        }),
        computed: {
            apiParams: function () {
                return {
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    filter: this.filter ? APIFilters.makeFilter(this.filter) : undefined,
                    sort: this.sort ? APIFilters.makeSort(this.sort) : undefined
                };
            },
            isSmall: function () {
                return this.$vuetify.breakpoint.smAndDown;
            },
            moreDataAvailable: function () {
                return this.tasksCount > this.tasks.length && this.tasksCount > 0;
            },
            moreDataText: function () {
                if (this.loading) {
                    return this.$t('base.list.loadingMore') + "..";
                }
                return this.$t('base.list.loadMore') + ' (' + (this.tasksCount - this.tasks.length) + ')';
            }
        },
        watch: {
            $props: {
                handler: function () {
                    this.fetchDataAfterFetchDone();
                },
                deep: true,
                immediate: true
            },

            tasks: function () {
                for (const task of this.tasks) {
                    if (!task.details && taskListDetails[task.type]) {
                        taskApi[task.type].get(task.id)
                            .then(response => {
                                this.$set(task, 'details', response.data);
                            }).catch(this.snack);
                    }
                }
            }
        },
        mounted: function () {
            window.addEventListener('scroll', this.onScroll);
        },
        activated: function () {
            this.active = true;
            this.fetchDataAfterFetchDone();
        },
        deactivated: function () {
            clearInterval(this.interval);
            this.interval = null;
            this.active = false;
        },
        destroyed: function () {
            clearInterval(this.interval);
            this.interval = null;
            window.removeEventListener('scroll', this.onScroll);
        },
        methods: {
            fetchData: function (rewrite = false) {
                if (!this.loading) {
                    this.loading = true;
                    if (rewrite) {
                        this.page = 1;
                    }
                    this.loadingPromise = TaskAPI.getAll(this.apiParams)
                        .then(response => {
                            this.tasksCount = response.data.item_count;
                            if (rewrite) {
                                this.tasks = response.data.items;
                            } else {
                                this.tasks = [
                                    ...this.tasks,
                                    ...response.data.items
                                ];
                            }
                        }).catch(() => {
                            this.snack('tasks.unableToLoad');
                        }).finally(() => {
                            this.loading = false;
                            this.loadingPromise = null;
                        });
                }
                return this.loadingPromise;
            },
            fetchDataAfterFetchDone: function () {
                if (this.loadingPromise !== null) {
                    this.loadingPromise.then(() => {
                        this.fetchData(true);
                    });
                } else {
                    this.fetchData(true);
                }
            },
            fetchMoreData: function () {
                if (!this.loading) {
                    this.page++;
                    this.fetchData();
                }
            },
            reloadData: function () {
                this.itemsPerPage = Math.min(100, 10 * this.page);
                const pageBak = Math.min(10, this.page);
                this.page = 1;
                this.fetchData(true)
                    .then(() => {
                        this.page = pageBak;
                        this.itemsPerPage = 10;
                    });
            },
            isScrolledIntoView: function (el) {
                const rect = el.getBoundingClientRect();
                return rect.top < window.innerHeight && rect.bottom >= 0;
            },
            onScroll: function () {
                if (!this.isSmall || !this.active) {
                    return;
                }
                if (this.isScrolledIntoView(this.$refs.moreData.$el) && this.moreDataAvailable) {
                    this.fetchMoreData();
                }
            }
        }
    };
</script>

<style scoped lang="sass">
.dashboardIcon:hover
    transform: rotate(-30deg)

.loadingIcon
    animation: rotation 2s infinite linear
</style>
