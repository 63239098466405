/**
 * Requires:
 * - this.reloadData: [Function]
 */
export const AutoRefreshMixin = {
    props: {
        autoRefresh: {
            type: Boolean,
            default: false
        },
        autoRefreshIn: {
            type: [Number, String],
            default: 60
        },
    },
    data: () => ({
        interval: null,
    }),
    watch: {
        autoRefresh: {
            handler: function (newVal) {
                if (newVal === false && this.interval !== null) {
                    clearInterval(this.interval);
                    this.interval = null;
                } else if (newVal === true && this.interval === null) {
                    this.interval = setInterval(this.reloadData, +this.autoRefreshIn * 1000);
                }
            },
            immediate: true
        },
    }
};
